import { Vue } from 'vue-class-component'
import SysCart from '@/types/SysCart'
import SysProduct from '@/types/SysProduct'
import { Products } from '@/services/ProductsDataService'
import BaseFunctions from '@/components/Utility/Base'
import { Competitions } from '@/services/CompetitionsDataService'

type dataReturnType = { error: any; cartItems: any; products: any }

export default class Cart extends Vue {
  private cartItems: SysCart[] = []
  private products: SysProduct[] = []
  private languageValue = 1
  private competitionRegistrationFeeProductId = 315
  error: any = null
  nowDate = new Date().toISOString()

  data (): dataReturnType {
    return {
      error: this.error,
      cartItems: this.cartItems,
      products: this.products
    }
  }

  public haveItemBeenTwoDaysInCart (dateString: string) : boolean {
    const d = new Date(this.nowDate)

    const dateTwoDaysAgo = d.setDate(d.getDate() - 2)

    return new Date(dateString).toISOString().split('T')[0] < new Date(dateTwoDaysAgo).toISOString().split('T')[0]
  }

  public haveItemExpired (dateString: string) : boolean {
    return new Date(this.nowDate).toISOString().split('T')[0] > new Date(dateString).toISOString().split('T')[0]
  }

  public addTwoDays (dateString: string) : string {
    const d = new Date(dateString)
    const twoDaysFromToday = d.setDate(d.getDate() - 2)
    console.log(new Date(twoDaysFromToday).toISOString().split('T')[0])

    return new Date(twoDaysFromToday).toISOString().split('T')[0]
  }

  public retrieveCart () : void {
    // First get date time from reliable source.
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        const tempNowDate = ((response.data).trim().length > 9 ? Date.parse((response.data).trim()) : this.nowDate)
        this.nowDate = new Date(tempNowDate).toISOString()
        console.log(this.nowDate)
      })
      .catch((err) => {
        this.error = err
      })
      .then(() => {
        // If there is not a local item named cart, then create one.
        if (!localStorage.getItem('cart')) {
          localStorage.setItem('cart', JSON.stringify([]))
        }

        // Parse the cart, and create an array
        this.cartItems = JSON.parse(localStorage.getItem('cart')!)

        if (this.cartItems.length > 0) {
          // Remove any items that have expired, or have been in the cart for more than two days.
          this.cartItems = this.cartItems.filter((element) => { return ((element.expirationDate === null && !this.haveItemBeenTwoDaysInCart(element.dateInCart)) || (element.expirationDate !== null && !this.haveItemExpired(element.expirationDate))) })
          localStorage.setItem('cart', JSON.stringify(this.cartItems))
        }
        if (this.cartItems.length > 0) {
          // If there is still products in the cart fetching the producs from the IP.
          let productParam = ''
          let productIdArray: number[] = []

          for (const objItem of this.cartItems) {
            if (objItem.productId !== 0 || objItem.productId !== null || objItem.productId !== undefined) {
              productIdArray.push(objItem.productId)
            }
          }

          // Eliminate duplicates from the array.
          productIdArray = Array.from(new Set(productIdArray))

          for (const item of productIdArray) {
            productParam += '&id=' + item.toString()
          }
          productParam = productParam.substring(1)

          console.log('Fetching products with ids: ' + JSON.stringify(productIdArray))

          Products.ProductsDataService.getAll('', null, productParam)
            .then((response) => {
              this.products = response.data
            })
            .catch((err) => {
              this.error = err
            })
        }
      })
  }

  public removeFromCart (cartId: number) : void {
    this.cartItems = JSON.parse(localStorage.getItem('cart')!)
    const indexCartItem = this.cartItems.findIndex(({ placeInCart }) => placeInCart === cartId)

    if (this.cartItems[indexCartItem].productId !== this.competitionRegistrationFeeProductId) {
      this.cartItems.splice(indexCartItem, 1)
      localStorage.setItem('cart', JSON.stringify(this.cartItems))
      console.log(localStorage.getItem('cart'))
    }
  }

  public emptyCart () : void {
    this.cartItems = []
    localStorage.removeItem('cart')
  }

  // If the cart contains a playerRegistration value that is not null, use the value and attempt to create a new entry in the player competition registration table.
  public tempPlayerRegistration () : void {
    const asyncCreateFunction = async (anyParams: any) => {
      await Competitions.CompetitionMemberRegistrationDataService.create(anyParams)
        .then((response) => {
          console.log(response.data)
        })
        .catch((err) => {
          this.error = err
        })
    }
    const promises = []

    for (let i = 0; i < this.cartItems.length; i++) {
      if (this.cartItems[i].playerRegistration !== null) {
        promises.push(asyncCreateFunction(this.cartItems[i].playerRegistration))
      }
    }

    Promise.all(promises)
      .then((response) => {
        const tempCart: SysCart[] = []

        for (const item of this.cartItems) {
          if (item.playerRegistration === null) {
            tempCart.push(item)
          }
        }
        this.cartItems = tempCart
        localStorage.setItem('cart', JSON.stringify(this.cartItems))
      })
      .catch((err) => {
        this.error = err
      })
  }

  public canHaveMultipleInCart (cartProductId: number | null) : boolean {
    let retVal = false
    const indexProduct = this.products.findIndex(x => x.id === cartProductId)

    if (indexProduct !== undefined && this.products[indexProduct] !== undefined) {
      retVal = this.products[indexProduct].produkt_type_id.produkt_type_unikt
    }

    return retVal
  }

  public calcTotal () : number {
    const sum = this.cartItems.reduce((acc, obj) => {
      return acc + (obj.price * obj.quantity)
    }, 0)

    return sum
  }

  async mounted () : Promise<void> {
    this.retrieveCart()
  }
}
